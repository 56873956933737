import React, { useState } from 'react';
import './App.css';
import Form from './Form';
import Result from './Result';
import logo from './assets/ola_logo.png';
// import instagramLogo from './assets/instagram.svg';
import facebookLogo from './assets/facebook.svg';
import Greeting from './Greeting';
// import CallForPeace from './CallForPeace';

function App() {
  const [dataSubmitted, setDataSubmitted] = useState(0);
  const [carEmissions, setCarEmissions] = useState(0);
  const [heaterEmissions, setHeaterEmissions] = useState(0);
  const [electricityEmissions, setElectricityEmissions] = useState(0);
  const [showForm, setShowForm] = useState(false);

  function getCarEmissions(emissions: number): void {
    console.log('Car Emissions:', emissions);
    setCarEmissions(emissions);
  }

  function getHeaterEmissions(emissions: number): void {
    console.log('Heater Emissions:', emissions);
    setHeaterEmissions(emissions);
  }

  function getElectricityEmissions(emissions: number): void {
    console.log('Electricity Emissions:', emissions);
    setElectricityEmissions(emissions);
  }

  function getDataSubmitted(value: number): void {
    console.log("getDataSubmitted called with value:", value);
    setDataSubmitted(value);
  }

  function handleShowForm() {
    setShowForm(true);
    setDataSubmitted(0);
  }

  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Raleway:300,500,700,900" rel="stylesheet"></link>
      {/* <div>
        <CallForPeace></CallForPeace>
      </div> */}
      <div id="root">
        <div className="sticky-header">
          <div className="header-content">
            <div className="header-content-left">
              <img src={logo} alt="Logo" />
            </div>
            <div className="header-content-center">
              <svg xmlns="http://www.w3.org/2000/svg" version="1.1" style={{ width: 0, height: 0 }}>
                <defs>
                  <filter id="goo">
                    <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
                    <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />
                    <feBlend in="SourceGraphic" in2="goo" />
                  </filter>
                </defs>
              </svg>
              <h1 className="title">
                Kriegsklima Rechner
                {/* <span className="drop"></span>
                <span className="drop"></span>
                <span className="drop"></span>
                <span className="drop"></span>
                <span className="drop"></span> */}
              </h1>
            </div>
            <div className="header-content-right">
            </div>
          </div>
        </div>
        <div className="form-container">
          {!showForm && <Greeting showForm={handleShowForm} />}
          {showForm && dataSubmitted === 1 &&
            <>
              <Result
                getDataSubmitted={getDataSubmitted}
                carEmissions={carEmissions}
                heaterEmissions={heaterEmissions}
                electricityEmissions={electricityEmissions} />
            </>}
          {showForm && dataSubmitted === 0 &&
            <>
              <Form
                getCarEmissions={getCarEmissions}
                getHeaterEmissions={getHeaterEmissions}
                getElectricityEmissions={getElectricityEmissions}
                getDataSubmitted={getDataSubmitted} />
            </>}
        </div>
        <footer className="footer">
          <div className="footer-text">
            <a href="https://github.com/Kriegsklima/Kriegsklima-Rechner/blob/main/README.md" rel="noopener noreferrer" target="_blank">Quellen</a>
          </div>
          <div className="footer-icons-center">
            <div className="footer-icons">
              {/* <img src={instagramLogo} alt="Instagram" /> */}
              <a href="https://www.facebook.com/OffeneLinkeAnsbach" target="_blank" rel="noreferrer">
                <img src={facebookLogo} alt="Facebook" />
              </a>
            </div>
          </div>
      </footer>
      </div>
    </>
  );
}

export default App;

export class Service{
    public calculateCarEmissions(type: number | null, consumptionLiter: number, totalKilometers: number): number{
        const verbrauch: number = (consumptionLiter * totalKilometers) / 100;
        return verbrauch * Number(type);
    }

    public calculateHeaterEmissions(type: number | null, livingSpace: number, roomtemperature: number): number{
        const hgt: number = roomtemperature * 180;
        const q: number = livingSpace * hgt * 0.024;
        const qTotal: number = q/0.9;
        const verbrauch: number = qTotal * Number(type);
        return verbrauch;
    }

    public calculateElectricityEmissions(type: number | null, consumptionKwh: number): number{
        const verbrauch: number = consumptionKwh * Number(type);
        return verbrauch;
    }
}

export enum ConsumptionTypeCar {
    benzinConsumptionPerLiter = 2.31,
    dieselConsumptionPerLiter = 2.64,
}

export enum ConsumptionTypeHeater {
    oilConsumptionPerKwh= 0.27,
    gasConsumptionPerKwh = 0.20,
    heathPumpConsumptionDtStrommixPerKwh = 0.50,
    districtHeatingConsumptionPerKwh = 0.13,
    woodHeatingConsumptionPerKwh = 0.03,
    heathPumpConsumptionÖkostromPerKwh = 0.00,
    solarHeatingConsumptionPerKwh = 0.00
}

export enum ConsumptionTypeElectricity {
    ökoElecriticityConsumptionPerKwh = 0.0,
    strommixDtElecriticityConsumptionPerKwh = 0.336,
    solarElecriticityConsumptionPerKwh = 0.0
}
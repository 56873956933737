import React, { useState, useRef, useEffect } from 'react';
import './Form.css';
import { ConsumptionTypeCar, ConsumptionTypeElectricity, ConsumptionTypeHeater, Service } from './Service';

export interface FormProps {
  getDataSubmitted(value: number): void;
  getCarEmissions(emissions: number): void;
  getHeaterEmissions(emissions: number): void;
  getElectricityEmissions(emissions: number): void;
}

function Form(props: FormProps) {
  const [livingSpace, setLivingSpace] = useState<number | null>(null);
  const [appartmentHeat, setAppartmentHeat] = useState<number | null>(null);
  const [heaterType, setHeaterType] = useState<number | null>(null);
  const [elecricityType, setElecricityType] = useState<number | null>(null);
  const [elecricityConsumption, setElecricityConsumption] = useState<number | null>(null);
  const [hasCar, setHasCar] = useState<number | null>(null);
  const [kilometerPerYear, setKilometerPerYear] = useState<number | null>(null);
  const [engineType, setEngineType] = useState<number | null>(null);
  const [carConsumptionLiter, setCarConsumptionLiter] = useState<number | null>(null);

  const [errors, setErrors] = useState<string[]>([]);
  const errorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  function validateForm(): boolean {
    const validationErrors: string[] = [];
    if (heaterType === null) validationErrors.push("Bitte wählen Sie eine Heizung.");
    if (livingSpace === null) validationErrors.push("Bitte geben Sie die Quadratmeter pro Bewohner an.");
    if (appartmentHeat === null) validationErrors.push("Bitte geben Sie die Durchschnittstemperatur an.");
    if (elecricityType === null) validationErrors.push("Bitte wählen Sie den Stromtyp.");
    if (elecricityConsumption === null) validationErrors.push("Bitte geben Sie den Stromverbrauch an.");
    if (hasCar === null) validationErrors.push("Bitte geben Sie an, ob Sie ein Auto besitzen.");

    if (hasCar === 1) {
      if (kilometerPerYear === null) validationErrors.push("Bitte geben Sie die jährlichen Kilometer an.");
      if (engineType === null) validationErrors.push("Bitte wählen Sie die Antriebsart des Autos.");
      if (carConsumptionLiter === null) validationErrors.push("Bitte geben Sie den Verbrauch des Autos auf 100km an.");
    }

    setErrors(validationErrors);
    return validationErrors.length === 0;
  }

  function submitValues(): void {
    if (validateForm()) {
      const _service: Service = new Service();
      if(hasCar === 1){
        props.getCarEmissions(_service.calculateCarEmissions(engineType!, carConsumptionLiter!, kilometerPerYear!));
      }
      props.getElectricityEmissions(_service.calculateElectricityEmissions(elecricityType!, elecricityConsumption!));
      props.getHeaterEmissions(_service.calculateHeaterEmissions(livingSpace!, heaterType!, appartmentHeat!));
      props.getDataSubmitted(1);
    }
  }

  return (
    <div className="container">
      {errors.length > 0 && (
        <div className="error-messages" ref={errorRef}>
          {errors.map((error, index) => (
            <p key={index} className="error">{error}</p>
          ))}
        </div>
      )}
      <form onSubmit={(e) => { e.preventDefault(); submitValues(); }}>
        <div className="row">
          <label htmlFor="heaterType">Was haben Sie für eine Heizung?</label>
          <select id="heaterType" name="heaterType" onChange={(e) => setHeaterType(Number(e.target.value))}>
            <option value="">Bitte wählen</option>
            <option value={ConsumptionTypeHeater.oilConsumptionPerKwh}>Öl</option>
            <option value={ConsumptionTypeHeater.gasConsumptionPerKwh}>Gas</option>
            <option value={ConsumptionTypeHeater.heathPumpConsumptionDtStrommixPerKwh}>Wärmepumpe mit dt. Strommix</option>
            <option value={ConsumptionTypeHeater.districtHeatingConsumptionPerKwh}>Fernwärme</option>
            <option value={ConsumptionTypeHeater.woodHeatingConsumptionPerKwh}>Holz</option>
            <option value={ConsumptionTypeHeater.heathPumpConsumptionÖkostromPerKwh}>Wärmepumpe Ökostrom</option>
            <option value={ConsumptionTypeHeater.solarHeatingConsumptionPerKwh}>Solarthermie</option>
          </select>
        </div>
        <div className="row">
          <label htmlFor="appartmentSize">Auf wie vielen Quadratmetern wohnen Sie (m² pro Bewohner)?</label>
          <select id="appartmentSize" name="appartmentSize" onChange={(e) => setLivingSpace(Number(e.target.value))}>
            <option value="">Bitte wählen</option>
            <option value={10}>bis 10m²</option>
            <option value={20}>bis 20m²</option>
            <option value={30}>bis 30m²</option>
            <option value={40}>bis 40m²</option>
            <option value={50}>bis 50m²</option>
            <option value={60}>bis 60m²</option>
            <option value={70}>bis 70m²</option>
            <option value={80}>bis 80m²</option>
            <option value={90}>bis 90m²</option>
            <option value={100}>bis 100m²</option>
            <option value={150}>über 100m²</option>
          </select>
        </div>
        <div className="row">
          <label htmlFor="appartmentHeat">Auf wie viel Grad heizen Sie ihre Wohnung im Winter durchschnittlich?</label>
          <select id="appartmentHeat" name="appartmentHeat" onChange={(e) => setAppartmentHeat(Number(e.target.value))}>
            <option value="">Bitte wählen</option>
            <option value={18}>18 °C</option>
            <option value={19}>19 °C</option>
            <option value={20}>20 °C</option>
            <option value={21}>21 °C</option>
            <option value={22}>22 °C</option>
            <option value={23}>23 °C</option>
            <option value={24}>24 °C</option>
          </select>
        </div>
        <div className="row">
          <label htmlFor="elecricityType">Welchen Strom beziehen Sie?</label>
          <select id="elecricityType" name="elecricityType" onChange={(e) => setElecricityType(Number(e.target.value))}>
            <option value="">Bitte wählen</option>
            <option value={ConsumptionTypeElectricity.ökoElecriticityConsumptionPerKwh}>Ökostrom</option>
            <option value={ConsumptionTypeElectricity.strommixDtElecriticityConsumptionPerKwh}>Strommix Deutschland</option>
            <option value={ConsumptionTypeElectricity.solarElecriticityConsumptionPerKwh}>Solaranlage</option>
          </select>
        </div>
        <div className="row">
          <label htmlFor="elecricityConsumption">Wie hoch ist ihr Stromverbrauch pro Jahr?</label>
          <select id="elecricityConsumption" name="elecricityConsumption" onChange={(e) => setElecricityConsumption(Number(e.target.value))}>
            <option value="">Bitte wählen</option>
            <option value={1000}>1000 kWh</option>
            <option value={1250}>1250 kWh</option>
            <option value={1500}>1500 kWh</option>
            <option value={2000}>2000 kWh</option>
            <option value={2500}>2500 kWh</option>
            <option value={3000}>3000 kWh</option>
            <option value={3500}>3500 kWh</option>
            <option value={4000}>4000 kWh</option>
          </select>
        </div>
        <div className="row">
          <label>Besitzen Sie ein Auto?</label>
          <div className="radio-container">
            <label className="radio-label">
              <input onChange={(e) => setHasCar(Number(e.target.value))} type="radio" id="carYes" name="car" value={1} /> Ja
            </label>
            <label className="radio-label">
              <input onChange={(e) => setHasCar(Number(e.target.value))} type="radio" id="carNo" name="car" value={0} /> Nein
            </label>
          </div>
        </div>
        {hasCar === 1 &&
          <>
            <div className="row">
              <label htmlFor="kilometerPerYear">Wie viele Kilometer fahren Sie jährlich?</label>
              <select id="kilometerPerYear" name="kilometerPerYear" onChange={(e) => setKilometerPerYear(Number(e.target.value))}>
                <option value="">Bitte wählen</option>
                <option value={0}>0 km</option>
                <option value={1000}>1.000 km</option>
                <option value={2000}>2.000 km</option>
                <option value={3000}>3.000 km</option>
                <option value={5000}>5.000 km</option>
                <option value={7500}>7.500 km</option>
                <option value={10000}>10.000 km</option>
                <option value={20000}>20.000 km</option>
                <option value={40000}>40.000 km</option>
                <option value={50000}>50.000 km</option>
              </select>
            </div>
            <div className="row">
              <label>Welche Antriebsart benutzt Ihr Auto?</label>
              <div className="radio-container">
                <label className="radio-label">
                  <input type="radio" id="antriebYes" name="carConsumption" value={ConsumptionTypeCar.benzinConsumptionPerLiter} onChange={(e) => setEngineType(Number(e.target.value))} /> Benzin
                </label>
                <label className="radio-label">
                  <input type="radio" id="antriebNo" name="carConsumption" value={ConsumptionTypeCar.dieselConsumptionPerLiter} onChange={(e) => setEngineType(Number(e.target.value))} /> Diesel
                </label>
              </div>
            </div>
            <div className="row">
              <label htmlFor="carConsumption">Welchen Verbrauch hat ihr Auto auf 100km?</label>
              <select id="carConsumption" name="carConsumption" onChange={(e) => setCarConsumptionLiter(Number(e.target.value))}>
                <option value="">Bitte wählen</option>
                <option value={3}>3 Liter</option>
                <option value={4}>4 Liter</option>
                <option value={5}>5 Liter</option>
                <option value={6}>6 Liter</option>
                <option value={7}>7 Liter</option>
                <option value={8}>8 Liter</option>
                <option value={9}>9 Liter</option>
                <option value={10}>10 Liter</option>
                <option value={11}>11 Liter</option>
                <option value={12}>12 Liter</option>
              </select>
            </div>
          </>
        }
        <div className="row">
          <input type="submit" value="Zum Ergebnis" />
        </div>
      </form>
    </div>
  );
}

export default Form;

import React, { useState, useEffect } from 'react';
import { BarChart } from '@mui/x-charts';
import './Result.css';
import environmentImage from './assets/congratsImage.png';

export interface ResultProps {
  getDataSubmitted(value: number): void;
  carEmissions: number;
  heaterEmissions: number;
  electricityEmissions: number;
}

const Result: React.FC<ResultProps> = ({ carEmissions, heaterEmissions, electricityEmissions, getDataSubmitted }) => {

  function calculateChinookCO2EmissionsPerHour(): number {
    const chinookFuelConsumptionPerHour = 1245; // in liters
    const co2PerLiterKerosene = 3.15; // kg CO2 per liter kerosene
    return chinookFuelConsumptionPerHour * co2PerLiterKerosene;
  }

  function calculateChinookFlightHours(co2Emissions: number): string {
    const chinookCO2PerHour = calculateChinookCO2EmissionsPerHour();
    const totalHours = co2Emissions / chinookCO2PerHour;
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);

    if (hours > 0) {
        return `${hours} Flugstunden`;
    } else {
        return `${minutes} Flugminuten`;
    }
}

  const carData = [
    { label: "Verbrauch Auto pro Jahr", value: Math.round(carEmissions) },
    { label: "Verbrauch Chinook pro Stunde", value: Math.round(calculateChinookCO2EmissionsPerHour()) }
  ];

  const heaterData = [
    { label: "Verbrauch Heizung pro Jahr", value: Math.round(heaterEmissions) },
    { label: "Verbrauch Herstellung 100 MG5", value: 10000 }
  ];

  const electricityData = [
    { label: "Verbrauch Strom pro Jahr", value: Math.round(electricityEmissions) },
    { label: "Verbrauch Leopard 2 pro Woche", value: Math.round((1565120 / 2) / 52) }
  ];

  const [chartWidth, setChartWidth] = useState(600);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setChartWidth(300); // For mobile devices
      } else if (window.innerWidth <= 1024) {
        setChartWidth(450); // For tablets
      } else {
        setChartWidth(600); // For desktops
      }
    };

    handleResize(); // Set the initial value
    window.addEventListener('resize', handleResize); // Update on resize

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="result-container">
      {carEmissions > 0 &&
        <>
          <h3>
            Der co2 Verbrauch Ihres Autos pro jahr entspricht <span className="red-text">{calculateChinookFlightHours(carEmissions)}</span> 
            &nbsp;eines in Katterbach stationierten US-Kampfhubschraubers Chinook CH-47 D
          </h3>
          <div className="chart-container">
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Co2 Verbrauch in KG'] }]}
              series={carData.map((data, index) => ({ label: data.label, color: ((index < 1) ? "#d4432c" : "#344028"), data: [data.value] }))}
              width={chartWidth}
              height={300}
              margin={{ top: 100 }} />
          </div>
        </>
      }
      {heaterEmissions > 0 &&
        <>
          <h3>
            Sie dürften für die Umwelt <span className="red-text">{Math.round(10000 / heaterEmissions)}</span> Jahre nicht heizen,
            damit Heckler&Koch weitere 100 MG5 für die Ukraine herstellen kann.
          </h3>
          <div className="chart-container">
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Co2 Verbrauch in KG'] }]}
              series={heaterData.map((data, index) => ({ label: data.label, color: ((index < 1) ? "#d4432c" : "#344028"), data: [data.value] }))}
              width={chartWidth}
              height={300}
              margin={{ top: 100 }} />
          </div>
        </>
      }
      {electricityEmissions > 0 &&
        <>
          <h3>
            Sie dürften für die Umwelt <span className="red-text">{Math.round(156512 / electricityEmissions)}</span> Jahre keinen Strom verbrauchen,
            damit ein Leopard 2 Panzer weitere 2 Jahre durch die Ukraine rollen kann.
          </h3>
          <div className="chart-container">
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['Co2 Verbrauch in KG'] }]}
              series={electricityData.map((data, index) => ({ label: data.label, color: ((index < 1) ? "#d4432c" : "#344028"), data: [data.value] }))}
              width={chartWidth}
              height={300}
              margin={{ top: 100 }} />
          </div>
        </>
      }
      {carEmissions <= 0 && heaterEmissions <= 0 && electricityEmissions <= 0 &&
        <div className="congratulations-container">
          <img src={environmentImage} alt="Environmentally Friendly" className="congratulations-image" />
          <h1 className="congratulations-text green-text">
            Glückwunsch! Sie verbrauchen bereits so wenig CO2, dass Sie nichts für den Krieg einsparen müssen.
          </h1>
        </div>
      }
      <button className="sticky-button" onClick={() => getDataSubmitted(0)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z" />
        </svg>
      </button>
    </div>
  );
};

export default Result;

import React from 'react';
import './Greeting.css';

interface GreetingProps {
  showForm: () => void;
}

const Greeting: React.FC<GreetingProps> = ({ showForm }) => {
  return (
    <div className="greeting-container">
      <div className="greeting-content">
        <h1>Liebe Mitbürgerinnen und Mitbürger,</h1>
        <p>die Erderwärmung ist messbar, und der Klimawandel ist spürbar. Die Regierung plant als Gegenmaßnahme ein Verbot von Verbrennermotoren und möchte Bürger zum teuren Einbau von Wärmepumpen zwingen.</p>
        
        <p>Gleichzeitig haben Ampel, Union und AfD beschlossen, zusätzlich 100 Milliarden Euro für Rüstung auszugeben. Immer mehr Kriegsgeräte werden von der Bundesregierung in Krisenregionen geliefert. Wie lässt sich das mit den Klimazielen vereinbaren?</p>
        <p>Krieg bedeutet nicht nur unendliches Leid und Zerstörung, sondern ist auch der größte Klimakiller. In Bayern trainiert die US-Armee auf verschiedenen Stützpunkten wie Grafenwöhr, Vilseck, Hohenfels und Ansbach mit Kampfhubschraubern und anderen Kriegsgeräten. Diese Trainingsflüge belasten die Umwelt massiv – beispielsweise verschmutzen die Hubschrauberflüge die Luft stärker als der gesamte PKW-Verkehr in den betroffenen Städten.</p>
        <p>Mit unserem Online-Tool haben Sie die Möglichkeit, Ihren persönlichen Energieverbrauch mit der Schadstoffemission aus der Rüstungsproduktion, den deutschen Waffenexporten in Krisengebiete und den Kriegsübungen der US-Kampfhubschrauber in Bayern zu vergleichen.</p>
        <button className="to-calculator-button" onClick={showForm}>Hier geht’s zum Rechner</button>
      </div>
    </div>
  );
};

export default Greeting;
